import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { Platform } from "react-native";
// Customizable Area End

import DocumentOpenerCommonController from "./DocumentOpenerCommonController";

export default class DocumentOpenerController extends DocumentOpenerCommonController {
  // Customizable Area Start
  assets = Platform.OS !== "macos" ? require("./assets") : null;
  docs = [
    {
      uri: this.assets?.dummpPdf,
    },
  ];
  // Customizable Area End
}
